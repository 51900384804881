import React, { useEffect } from "react";
import CircleType from "circletype";

const RealLabel60 = ({
  shape,
  symbol1,
  symbol2,
  productName,
  productSubtext,
  product,
  signalWord,
  hazardStatement1,
  hazardStatement2,
  selectedPic,
  businessName,
  businessAddress,
  businessTelephone,
  massOrVolume,
  unit,
  ufi,
  batchNumber,
  textColor,
  contentPadding,
  addressMargin,
  fontSize,
}) => {
  useEffect(() => {
    new CircleType(document.getElementById("demo3")).radius(117);
    console.log(productName);
  }, [productName]);
  // console.log(productSubtext.length);
  return (
    <div
      id="real_label"
      className="label"
      style={{
        color: textColor,
        width: "60mm",
        height: "60mm",
        fontSize: `${fontSize / 2.65}pt`,
        // lineHeight: "1.7",
      }}
    >
      <div className="test-class1">
        <div style={{ height: "18.9px" }}>
          <span id="demo3" style={{ fontSize: "2.8mm", fontWeight: 500 }}>
            {productName}
          </span>
        </div>
        <div
          className="product-subtext"
          style={{
            // marginTop: "75px",
            marginBottom: "7.56px",
            minHeight: `15.5px`,
            margin: "0px 37.8px",
          }}
        >
          <div id="subtext-padding-left60"></div>
          <div id="subtext-padding-right60"></div>
          <p
            style={{
              // fontSize: "14px",
              marginBottom: "0px",
              marginTop: `${contentPadding / 2.65}px`,
              // wordBreak: "break-all",
              // lineHeight: hazardStatements.length > 100 ? "1.3" : "1.5",
              padding: "0px 7.56px",
            }}
          >
            {productSubtext}
          </p>
        </div>
        {symbol1 === "/symbols/1.png" ? (
          <div className="yellow-warning60">
            <div className="yellow-warning-img60">
              {/* {selectedPic.length === 0 && <img
                src={symbol1}
                alt="1"
                className="first_symbol"
                style={{
                  width: "10mm",
                }}
              />} */}
            </div>
            <p
              className="hazard"
              style={{
                // fontSize: "14px",
                marginBottom: 0,
                // lineHeight: hazardStatements.length > 800 ? "1.3" : "1.5",
                // wordBreak: "break-all",
                padding: "0px 6.4px",
              }}
            >
              <b>{`${signalWord} `}</b>
              {hazardStatement1}
              <span>{hazardStatement2}</span>
            </p>
          </div>
        ) : (
          <div
            className="product"
            style={{
              marginLeft: "15.1px",
              marginRight: "15.1px",
              minHeight: "58.6px",
            }}
          >
            <div id="text-padding-left60"></div>
            <div id="text-padding-right60"></div>
            {symbol1 ? (
              <img
                src={symbol1}
                alt="1"
                className="first_symbol"
                style={{
                  width: "58.583px",
                  minWidth: `${massOrVolume >= 125 ? "58.583px" : "0mm"}`,
                  shapeOutside: "url(" + symbol1 + ")",
                }}
              />
            ) : (
              <div id="polygon-left60"></div>
            )}
            {symbol2 ? (
              <img
                src={symbol2}
                alt="1"
                className="second_symbol"
                id="second_symbol"
                style={{
                  width: "58.583px",
                  minWidth: `${massOrVolume >= 125 ? "58.583px" : "0mm"}`,
                  shapeOutside: "url(" + symbol2 + ")",
                }}
              />
            ) : (
              <div id="polygon60"></div>
            )}

            <p
              className="hazard"
              style={{
                // fontSize: "14px",
                marginBottom: 0,
                // lineHeight: hazardStatements.length > 800 ? "1.3" : "1.5",
                // wordBreak: "break-all",
                padding: "0px 6.4px",
              }}
            >
              <b>{`${signalWord} `}</b>
              {hazardStatement1}{" "}
              <span className="break-word">{hazardStatement2}</span>
            </p>
          </div>
        )}

        {shape === "circle1" ? (
          ""
        ) : product && product.includes("Candle") ? (
          <div
            className="pictogram"
            style={{
              height: "24.6px",
              position: "absolute",
              width: "226.8px",
              top: "164px",
              marginTop: `${addressMargin/2.646}px`,
            }}
          >
            {selectedPic.length !== 0 && <img
              src={"/symbols/1.png"}
              alt="element"
              style={{
                width: "20.8px",
                minWidth: "5mm",
                margin: "1.51px",
              }}
            />}
            {selectedPic.map((element, index) => {
              return (
                <img
                  style={{
                    width: "20.8px",
                    minWidth: "5mm",
                    margin: "1.51px",
                  }}
                  src={element}
                  alt="element"
                  key={index}
                />
              );
            })}
          </div>
        ) : (
          ""
        )}
        <div>
          <p
            className="mb-0 batch_pos60"
            style={{
              marginTop: `${addressMargin/2.646}px`,
              left: `${(198 - (fontSize - 10) * 10)/2.646}px`,
            }}
          >
            <b>{batchNumber}</b>
          </p>
          <p
            className="mb-0 ufi_pos60"
            style={{
              marginTop: `${addressMargin/2.646}px`,
              left: `${(298 - (fontSize - 10) * 10)/2.646}px`,
            }}
          >
            <b>{ufi}</b>
          </p>
        </div>
        <div
          className="business"
          style={{
            // fontSize: "16px",
            marginLeft: "34px",
            marginRight: "34px",
            width: "158.4px",
            height: "28.7px",
            position: "absolute",
            top: "195px",
            marginTop: `${addressMargin/2.646}px`,
          }}
        >
          <p className="mb-0">
            {businessName}, {businessAddress} Tel: {businessTelephone}
          </p>
          <p className="mb-0">
            <b>
              ~{massOrVolume}
              {unit} Net
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RealLabel60;
