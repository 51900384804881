import React from "react";

const Square = ({
  symbol1,
  symbol2,
  productName,
  productSubtext,
  signalWord,
  hazardStatement1,
  hazardStatement2,
  selectedPic,
  businessName,
  businessAddress,
  businessTelephone,
  massOrVolume,
  unit,
  ufi,
  batchNumber,
  textColor,
  contentPadding,
  addressMargin,
  product,
  fontSize,
}) => {
  return (
    <div
      id="label"
      className="text-center"
      style={{
        color: textColor,
        width: "600px",
        height: "600px",
        padding: "20px",
        fontSize: `${fontSize}pt`,
      }}
    >
      <div>
        <h3>{productName}</h3>
      </div>
      <p
        style={{
          // fontSize: "16px",
          minHeight: `35px`,
          marginTop: `${contentPadding}px`,
        }}
      >
        <i>{productSubtext}</i>
      </p>
      <p className="mb-0">
        <b>{signalWord}</b>
      </p>
      <p
        style={{
          // fontSize: "16px",
          marginBottom: "0px",
          lineHeight:
            (hazardStatement1 + hazardStatement2).length > 800 ? "1.4" : "1.5",
        }}
      >
        {hazardStatement1}{" "}
        <span className="break-word">{hazardStatement2}</span>
      </p>
      <div
        className="pictogram"
        style={{
          height: "70px",
          position: "absolute",
          width: "560px",
          top: "395px",
          marginTop: `${addressMargin}px`,
        }}
      >
        {product && product.includes("Candle") ? (
          <div>
            {selectedPic.length !== 0 && <img
              className="m-1"
              src={"/symbols/1.png"}
              alt="element"
              style={{
                width: "60px",
                minWidth: "5mm",
              }}
            />}
            {selectedPic.map((element, index) => {
              return (
                <img
                  className="m-1"
                  style={{
                    width: "60px",
                    minWidth: "5mm",
                  }}
                  src={element}
                  alt="element"
                  key={index}
                />
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="business"
        style={{
          position: "absolute",
          width: "560px",
          top: "462px",
          marginTop: `${addressMargin}px`,
        }}
      >
        {/* {symbol1 === "/symbols/1.png" ?
          (selectedPic.length === 0 && <img
            src={symbol1}
            alt="1"
            className="first_symbol"
            style={{
              width: "140px",
              minWidth: `${massOrVolume >= 125 ? "140px" : "0mm"}`,
              shapeOutside: "url(" + symbol1 + ")",
            }}
          />
          ) : */}
        {symbol1 !== "/symbols/1.png" && (
          symbol1 && <img
            src={symbol1}
            alt="1"
            className="first_symbol"
            style={{
              width: "140px",
              minWidth: `${massOrVolume >= 125 ? "140px" : "0mm"}`,
              shapeOutside: "url(" + symbol1 + ")",
            }}
          />
        )}

        {symbol2 ? (
          <img
            src={symbol2}
            alt="1"
            className="second_symbol"
            id="second_symbol"
            style={{
              width: "140px",
              minWidth: `${massOrVolume >= 125 ? "140px" : "0mm"}`,
              shapeOutside: "url(" + symbol2 + ")",
            }}
          />
        ) : (
          ""
        )}

        <p className="mb-0">
          {businessName}, {businessAddress}
        </p>
        <p>Tel: {businessTelephone}</p>
        <p>
          <b>
            ~{massOrVolume}
            {unit} Net
          </b>
        </p>
      </div>
      <div>
        <div className="batch_pos2">
          <p>
            <b>{batchNumber}</b>
          </p>
        </div>
        <div
          className="ufi_pos2"
          style={{ left: `${442 - (fontSize - 10) * 18}px` }}
        >
          <p>
            <b>{ufi}</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Square;
