import React, { useState, useEffect } from "react";
import Label from "./label";
import RealLabel60 from "./realLabel60";
import Square from "./square";
import RealSquare60 from "./realSquare60";
import ReactFlagsSelect from "react-flags-select";
import html2pdf from "html2pdf.js";
import { isMobile } from "react-device-detect";
import { get_fragrances, get_products, get_template } from "./api/template";
import { useTranslation } from "react-i18next";
import "./index.scss";

function App() {
  const { t, i18n } = useTranslation();
  const [fragrances, setFragrances] = useState([]);
  const [fragrance, setFragrance] = useState(0);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(0);
  const [language, setLanguage] = useState("ENGLISH");
  const [selectedLanguage, setSelectedLanguage] = useState("GB");
  const [shape, setShape] = useState("circle_60");
  const [background, setBackground] = useState("#FFFFFF");
  const [textColor, setTextColor] = useState("black");
  const [productName, setProductName] = useState("Product Name");
  const [businessName, setBusinessName] = useState("Your Business Name");
  const [businessAddress, setBusinessAddress] = useState(
    "Address Line 1, Town/City, County and Postcode."
  );
  const [businessTelephone, setBusinessTelephone] = useState("01234567890");
  const [massOrVolume, setMassOrVolume] = useState("220");
  const [unit, setUnit] = useState("g");
  const [selectedPic, setSelectedPic] = useState([]);
  const [statusPic, setStatusPic] = useState([]);
  const [ufi, setUfi] = useState("UFI:XXXX-XXXX-XXXX-XXXX");
  const [batchNumber, setBatchNumber] = useState("BN:1123");
  const [showBorder, setShowBorder] = useState(true);
  const [symbol1, setSymbol1] = useState("");
  const [symbol2, setSymbol2] = useState("");
  const [productSubtext, setProductSubtext] = useState("");
  const [signalWord, setSignalWord] = useState("");
  const [hazardStatement1, setHazardStatement1] = useState("");
  const [hazardStatement2, setHazardStatement2] = useState("");
  const [loading, setLoading] = useState(false);
  const [contentPadding, setContentPadding] = useState(0);
  const [addressMargin, setAddressMargin] = useState(0);
  const [fontSize, setFontSize] = useState(10);
  const pictograms = [
    "/pictogram/3_Black-01.png",
    "/pictogram/3_White-01.png",
    "/pictogram/4_Black-01.png",
    "/pictogram/4_White-01.png",
    "/pictogram/5_Black-01.png",
    "/pictogram/5_White-01.png",
    "/pictogram/6_Black-01.png",
    "/pictogram/6_White-01.png",
    "/pictogram/A1_Black-01.png",
    "/pictogram/A1_White-01.png",
    "/pictogram/A2_Black-01.png",
    "/pictogram/A2_White-01.png",
  ];
  const symbols = [
    "/symbols/1.png",
    "/symbols/2.png",
    "/symbols/3.png",
    "/symbols/4.png",
    "/symbols/5.png",
  ];

  useEffect(() => {
    handleStatusPic();

    setLoading(true);
    let url_arr = window.location.href.split("/");
    if (url_arr[url_arr.length - 1] === "fr") i18n.changeLanguage("fr");
    else if (url_arr[url_arr.length - 1] === "de") i18n.changeLanguage("de");

    get_fragrances().then((res) => {
      if (res.status === 200) {
        setLoading(false);

        setFragrances(res.data);
      } else {
        console.log(res);
      }
    });
  }, []);

  useEffect(() => {
    if (product !== 0 && fragrance !== 0 && product !== "0") {
      setLoading(true);

      get_template({
        fragrance: fragrance,
        product: product,
        language: language,
      }).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          if (res.data.subtext) setProductSubtext(`(${res.data.subtext})`);
          else setProductSubtext("");
          if (res.data.signal_word) setSignalWord(`${res.data.signal_word}.`);
          else setSignalWord("");
          let hazard_str1 = `${res.data.hazards} ${res.data.precaution} ${res.data.contains} `;
          let hazard_str2 = `${res.data.allergens ?? ''} ${res.data?.rule}`;
          setHazardStatement1(hazard_str1);
          setHazardStatement2(hazard_str2);
          setSymbol1(symbols[res.data.symbol[0]]);
          setSymbol2(symbols[res.data.symbol[1]]);
          setProductName(fragrance);
        } else {
          console.log(res);
        }
      });
    }
  }, [product, language]);

  const handleStatusPic = () => {
    let all_status = [];
    pictograms.map((element) => {
      let new_status = {
        value: element,
        checked: false,
      };
      all_status.push(new_status);
    });
    setStatusPic(all_status);
  };

  const handlePictograms = (e, ind) => {
    if (selectedPic.length < 5) {
      let pic_array = JSON.stringify(selectedPic);
      pic_array = JSON.parse(pic_array);
      if (e.target.checked) pic_array.push(e.target.value);
      else {
        let index = pic_array.indexOf(e.target.value);
        if (index >= 0) pic_array.splice(index, 1);
      }
      let changedStatus = JSON.stringify(statusPic);
      changedStatus = JSON.parse(changedStatus);
      changedStatus[ind].checked = e.target.checked;
      setStatusPic(changedStatus);
      setSelectedPic(pic_array);
    } else if (selectedPic.length === 5 && e.target.checked === false) {
      let pic_array = JSON.stringify(selectedPic);
      pic_array = JSON.parse(pic_array);
      let index = pic_array.indexOf(e.target.value);
      if (index >= 0) pic_array.splice(index, 1);
      let changedStatus = JSON.stringify(statusPic);
      changedStatus = JSON.parse(changedStatus);
      changedStatus[ind].checked = e.target.checked;
      setStatusPic(changedStatus);
      setSelectedPic(pic_array);
    } else alert("You can only select 5");
  };

  const refresh = () => {
    window.location.reload();
  };

  const download = () => {
    const element = document.getElementById("label_download_real");

    var opt = {
      margin: [0, 0, 0, 0],
      filename: "label.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        scale: 10,
        scrollX: 0,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: [63.3, 63.3], orientation: "landscape" },
    };
    // Choose the element and save the PDF for our user.
    html2pdf().from(element).set(opt).save();
  };

  const handleFragranceChange = (e) => {
    setFragrance(e.target.value);
    get_products({ fragrance: e.target.value }).then((res) => {
      if (res.status === 200) {
        setProducts(res.data);
      } else {
        console.log(res);
      }
    });
    setProduct(0);
  };

  const handleProductChange = (e) => {
    setProduct(e.target.value);
  };

  const handleContentPadding = (value) => {
    if (value > 0) setContentPadding(value);
  };

  const handleAddressMargin = (value) => {
    setAddressMargin(value);
  };

  const handleLanguage = (value) => {
    setSelectedLanguage(value);
    if (value === "GB") setLanguage("ENGLISH");
    else if (value === "BG") setLanguage("BULGARIAN");
    else if (value === "CZ") setLanguage("CZECH");
    else if (value === "DK") setLanguage("DANISH");
    else if (value === "NL") setLanguage("DUTCH");
    else if (value === "EE") setLanguage("ESTONIAN");
    else if (value === "FI") setLanguage("FINNISH");
    else if (value === "FR") setLanguage("FRENCH");
    else if (value === "DE") setLanguage("GERMAN");
    else if (value === "GR") setLanguage("GREEK");
    else if (value === "HU") setLanguage("HUNGARIAN");
    else if (value === "IE") setLanguage("IRISH");
    else if (value === "IT") setLanguage("ITALIAN");
    else if (value === "LV") setLanguage("LATVIAN");
    else if (value === "LT") setLanguage("LITHUANIAN");
    else if (value === "MT") setLanguage("MALTESE");
    else if (value === "NO") setLanguage("NORWEGIAN");
    else if (value === "PL") setLanguage("POLISH");
    else if (value === "PT") setLanguage("PORTUGESE");
    else if (value === "RO") setLanguage("ROMANIAN");
    else if (value === "SK") setLanguage("SLOVAK");
    else if (value === "SI") setLanguage("SLOVENIAN");
    else if (value === "ES") setLanguage("SPANISH");
    else if (value === "SE") setLanguage("SWEDISH");
  };

  return isMobile ? (
    <div className="mobile_view">
      <h3>Please view on a Desktop</h3>
    </div>
  ) : (
    <div className="App row justify-content-center">
      <div>
        <div>
          <img className="company-logo" src="/logo.png" alt=""></img>
        </div>
        <a
          className="company-tutorial"
          href="https://candle-shack.co.uk/blogs/candle-wicks/clp-label-design-tool"
          target="_blank"
        >
          {t("learn_clp")}
        </a>
      </div>
      {fragrance !== 0 && product !== 0 && !loading && product !== "0" ? (
        <div className="left_section col-sm-5 col-12">
          <div
            className="label_area d-flex justify-content-center align-items-center"
            id="label_download"
            style={{
              backgroundColor: background,
              borderRadius: shape === "circle_60" ? "50%" : "",
              position: "absolute",
            }}
          >
            {shape === "square" ? (
              <div
                style={{
                  backgroundColor: background,
                  borderStyle: "dashed",
                  borderColor: showBorder ? textColor : background,
                }}
              >
                <Square
                  shape={shape}
                  symbol1={symbol1}
                  symbol2={symbol2}
                  productName={productName}
                  productSubtext={productSubtext}
                  product={product}
                  signalWord={signalWord}
                  hazardStatement1={hazardStatement1}
                  hazardStatement2={hazardStatement2}
                  selectedPic={selectedPic}
                  businessName={businessName}
                  businessAddress={businessAddress}
                  businessTelephone={businessTelephone}
                  massOrVolume={massOrVolume}
                  unit={unit}
                  ufi={ufi}
                  batchNumber={batchNumber}
                  background={background}
                  textColor={textColor}
                  showBorder={showBorder}
                  contentPadding={contentPadding}
                  addressMargin={addressMargin}
                  fontSize={fontSize}
                />
              </div>
            ) : (
              <div
                style={{
                  borderRadius: "50%",
                  backgroundColor: background,
                  borderStyle: "dashed",
                  borderColor: showBorder ? textColor : background,
                }}
              >
                <Label
                  shape={shape}
                  symbol1={symbol1}
                  symbol2={symbol2}
                  productName={productName}
                  productSubtext={productSubtext}
                  product={product}
                  signalWord={signalWord}
                  hazardStatement1={hazardStatement1}
                  hazardStatement2={hazardStatement2}
                  selectedPic={selectedPic}
                  businessName={businessName}
                  businessAddress={businessAddress}
                  businessTelephone={businessTelephone}
                  massOrVolume={massOrVolume}
                  unit={unit}
                  ufi={ufi}
                  batchNumber={batchNumber}
                  background={background}
                  textColor={textColor}
                  showBorder={showBorder}
                  contentPadding={contentPadding}
                  addressMargin={addressMargin}
                  fontSize={fontSize}
                />
              </div>
            )}
          </div>
          <div style={{ marginTop: 20 }}>
            <div
              className="d-flex justify-content-center align-items-center"
              id="label_download_real"
            >
              <div
                className="label_area60 d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: background,
                  borderRadius: shape === "circle_60" ? "50%" : "",
                }}
              >
                {shape === "square" ? (
                  <div
                    style={{
                      backgroundColor: background,
                    }}
                  >
                    <RealSquare60
                      shape={shape}
                      symbol1={symbol1}
                      symbol2={symbol2}
                      productName={productName}
                      productSubtext={productSubtext}
                      product={product}
                      signalWord={signalWord}
                      hazardStatement1={hazardStatement1}
                      hazardStatement2={hazardStatement2}
                      selectedPic={selectedPic}
                      businessName={businessName}
                      businessAddress={businessAddress}
                      businessTelephone={businessTelephone}
                      massOrVolume={massOrVolume}
                      unit={unit}
                      ufi={ufi}
                      batchNumber={batchNumber}
                      background={background}
                      textColor={textColor}
                      showBorder={showBorder}
                      contentPadding={contentPadding}
                      addressMargin={addressMargin}
                      fontSize={fontSize}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: "50%",
                      backgroundColor: background,
                    }}
                  >
                    <RealLabel60
                      shape={shape}
                      symbol1={symbol1}
                      symbol2={symbol2}
                      productName={productName}
                      productSubtext={productSubtext}
                      product={product}
                      signalWord={signalWord}
                      hazardStatement1={hazardStatement1}
                      hazardStatement2={hazardStatement2}
                      selectedPic={selectedPic}
                      businessName={businessName}
                      businessAddress={businessAddress}
                      businessTelephone={businessTelephone}
                      massOrVolume={massOrVolume}
                      unit={unit}
                      ufi={ufi}
                      batchNumber={batchNumber}
                      background={background}
                      textColor={textColor}
                      showBorder={showBorder}
                      contentPadding={contentPadding}
                      addressMargin={addressMargin}
                      fontSize={fontSize}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 400 }}>
            <div className="label_area1 d-flex justify-content-center align-items-center">
              <div>{t("text_up_down")}: &nbsp;</div>
              <i
                className="fa fa-arrow-up"
                aria-hidden="true"
                onClick={() => handleContentPadding(contentPadding - 1)}
              ></i>
              &nbsp;/&nbsp;
              <i
                className="fa fa-arrow-down"
                aria-hidden="true"
                onClick={() => handleContentPadding(contentPadding + 1)}
              ></i>
            </div>
            <div className="label_area1 d-flex justify-content-center align-items-center">
              <div>{t("address_up_down")}: &nbsp;</div>
              <i
                className="fa fa-arrow-up"
                aria-hidden="true"
                onClick={() => handleAddressMargin(addressMargin - 1)}
              ></i>
              &nbsp;/&nbsp;
              <i
                className="fa fa-arrow-down"
                aria-hidden="true"
                onClick={() => handleAddressMargin(addressMargin + 1)}
              ></i>
            </div>
            <div className="label_area1 d-flex justify-content-center align-items-center">
              <div>{t("font_size")}: &nbsp;</div>
              <select
                className="form-control"
                style={{ width: "80px" }}
                onChange={(e) => setFontSize(e.target.value)}
                value={fontSize}
              >
                <option value={10}>10pt</option>
                <option value={11}>11pt</option>
                <option value={12}>12pt</option>
                <option value={13}>13pt</option>
              </select>
            </div>
            <button
              className="btn btn-dark"
              onClick={() => setShowBorder(!showBorder)}
            >
              {t("show_hide_border")}
            </button>
          </div>
        </div>
      ) : (
        <div className="left_section col-sm-5 col-12 d-flex justify-content-center align-items-center">
          {loading && <img src="gif/loading.gif" alt="gif" />}
        </div>
      )}
      <div className="right_section col-sm-5 col-12">
        <div className="form-group row">
          <label className="col-sm-3 col-3 col-form-label">{t("fragrance")}:</label>
          <div className="col-sm-9 col-9">
            <select
              className="form-control"
              onChange={handleFragranceChange}
              value={fragrance}
            >
              <option value={0}>{t("not_selected")}</option>
              {fragrances.map((fragrance, index) => {
                return (
                  <option key={index} value={fragrance}>
                    {fragrance}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("product")}:</label>
          <div className="col-sm-9">
            <select
              className="form-control"
              onChange={handleProductChange}
              value={product}
            >
              <option value={0}>{t("not_selected")}</option>
              {products.map((product, index) => {
                return (
                  <option key={index} value={product}>
                    {product}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("language")}:</label>
          <div className="col-sm-5">
            <ReactFlagsSelect
              countries={[
                "GB",
                "BG",
                "CZ",
                "DK",
                "NL",
                "EE",
                "FI",
                "FR",
                "DE",
                "GR",
                "HU",
                "IE",
                "IT",
                "LV",
                "LT",
                "MT",
                "NO",
                "PL",
                "PT",
                "RO",
                "SK",
                "SI",
                "ES",
                "SE",
              ]}
              customLabels={{
                GB: "ENGLISH",
                BG: "BULGARIAN",
                CZ: "CZECH",
                DK: "DANISH",
                NL: "DUTCH",
                EE: "ESTONIAN",
                FI: "FINNISH",
                FR: "FRENCH",
                DE: "GERMAN",
                GR: "GREEK",
                HU: "HUNGARIAN",
                IE: "IRISH",
                IT: "ITALIAN",
                LV: "LATVIAN",
                LT: "LITHUANIAN",
                MT: "MALTESE",
                NO: "NORWEGIAN",
                PL: "POLISH",
                PT: "PORTUGESE",
                RO: "ROMANIAN",
                SK: "SLOVAK",
                SI: "SLOVENIAN",
                ES: "SPANISH",
                SE: "SWEDISH",
              }}
              placeholder="Select Language"
              onSelect={handleLanguage}
              selected={selectedLanguage}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("mass_volume")}:</label>

          <div className="col-sm-3">
            <input
              type="text"
              className="form-control"
              value={massOrVolume}
              onChange={(e) => setMassOrVolume(e.target.value)}
              placeholder="Mass/Volume"
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-3 pt-0">{t("units")}:</label>

          <div className="col-sm-9 row m-0">
            <div className="form-check col-sm-4">
              <input
                className="form-check-input"
                type="radio"
                name="value"
                id="g"
                value="g"
                checked={unit === "g"}
                onChange={(e) => setUnit(e.target.value)}
              />
              <label className="form-check-label">{t("grams")}</label>
            </div>
            <div className="form-check col-sm-4">
              <input
                className="form-check-input"
                type="radio"
                name="value"
                id="ml"
                value="ml"
                checked={unit === "ml"}
                onChange={(e) => setUnit(e.target.value)}
              />
              <label className="form-check-label">{t("mililiters")}</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-3 pt-0">
            {t("shape")}: <br /> {t("choose_one")}
          </label>
          <div className="col-sm-9 row m-0 text-center">
            <div className="col-sm-3">
              <div className="d-flex justify-content-center">
                <span className="circle row"></span>
              </div>
              <div className="d-flex justify-content-center">
                <span>60mm</span>
              </div>
              <input
                type="radio"
                name="shape"
                id="circle"
                value="circle_60"
                checked={shape === "circle_60"}
                onChange={(e) => setShape(e.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <div className="d-flex justify-content-center">
                <span className="square row"></span>
              </div>
              <div className="d-flex justify-content-center">
                <span>60mm</span>
              </div>
              <input
                type="radio"
                name="shape"
                id="square"
                value="square"
                checked={shape === "square"}
                onChange={(e) => setShape(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("background")}:</label>
          <div className="col-sm-9">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">{background}</span>
              </div>
              <input
                type="color"
                className="form-control"
                value={background}
                onChange={(e) => setBackground(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-3 pt-0">{t("text")}:</label>
          <div className="col-sm-9 row m-0">
            <div className="form-check col-sm-4">
              <input
                className="form-check-input"
                type="radio"
                value="black"
                checked={textColor === "black"}
                onChange={(e) => setTextColor(e.target.value)}
              />
              <label className="form-check-label">{t("black")}</label>
            </div>
            <div className="form-check col-sm-4">
              <input
                className="form-check-input"
                type="radio"
                value="white"
                checked={textColor === "white"}
                onChange={(e) => setTextColor(e.target.value)}
              />
              <label className="form-check-label">{t("white")}</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("product_name")}:</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("business_name")}:</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("business_address")}:</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              value={businessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("business_telephone")}:</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              value={businessTelephone}
              onChange={(e) => setBusinessTelephone(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("ufi")}:</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              value={ufi}
              onChange={(e) => setUfi(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">{t("batch_number")}:</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              value={batchNumber}
              onChange={(e) => setBatchNumber(e.target.value)}
            />
          </div>
        </div>
        {product && product.includes("Candle") ? (
          <div className="form-group row">
            <label className="col-form-label col-sm-3 pt-0">
              EN 15494:
              <br /> ({t("choose_all_applicable")})
              <br /> {t("max_5")}
            </label>
            <div className="col-sm-9 row m-0 text-center">
              {statusPic.map((element, index) => {
                return (
                  <div className="col-sm-2" key={index}>
                    <div className="d-flex justify-content-center">
                      <img
                        className="pictogram_img m-1"
                        src={element.value}
                        alt="element"
                      ></img>
                    </div>
                    <input
                      type="checkbox"
                      id="circle"
                      value={element.value}
                      checked={element.checked}
                      onChange={(e) => handlePictograms(e, index)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row d-flex justify-content-center mt-3">
          <button className="btn btn-dark col-2 mr-3" onClick={refresh}>
            {t("refresh")}
          </button>
          <button className="btn btn-dark col-3" onClick={download}>
            {t("save_pdf")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
